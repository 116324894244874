import { GenIcon } from "react-icons";

export default function RubikaIcon(props) {
  return GenIcon({
    tag: "svg",
    attr: { viewBox: "0 0 495.37 543.04" },
    child: [
      {
        tag: "path",
        attr: {
          fill: "#b8ce01",
          d: "M246.07,0h1.76c.48,42.73-.25,128.44-.25,128.44-40.86-22.92-82-45.3-122.84-68.29q40.13-22.81,80.32-45.51c12.69-7.16,26.33-13.31,41-14.64Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#7db425",
          d: "M247.73,0h3C266.62,1.43,281,9,294.67,16.68Q332.91,38.25,371.05,60c-41,22.73-82.45,45.82-123.51,68.48-.35-42.73.63-85.77.15-128.5Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#f6a925",
          d: "M124.77,60.11,247.61,128.4,123.92,200.66s-.06-45.85.08-68.71C123.89,108.21,124.77,60.11,124.77,60.11Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#35ac9d",
          d: "M371.19,60l.35.19c-.1,42.59,0,85.18,0,127.77-.07,4.17-.11,12.77-.11,12.77L247.62,128.45S330.2,82.76,371.23,60Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#59d6bd",
          d: "M371.44,60.21c27.28,15.51,54.37,31.36,81.47,47.17,13.11,7.36,27,15.52,34.16,29.39-38.38,21.25-115.68,64-115.68,64s-.06-8.61,0-12.78q0-63.89,0-127.77Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#ef7414",
          d: "M46.5,105c25.65-15,78.19-44.88,78.19-44.88L124,200.66S47,158.21,8.71,137C17.05,122.13,32.11,113.12,46.5,105Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#fff",
          d: "M247.5,128.44l123.81,72.3s-39.84,22-59.62,32.64c-21.14,12-64.09,35.82-64.09,35.82L123.94,200.66Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#e74b50",
          d: "M0,193C.11,174.17-.23,154.15,8.66,137,47,158.21,123.92,200.66,123.92,200.66,82.55,224.58,41.38,248.86,0,272.81,0,246.21,0,219.62,0,193Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#794387",
          d: "M487.07,136.77c7.54,14.1,8.64,30.5,8.22,46.19v90.11L371.41,200.76S448.69,158,487.07,136.77Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#e4e4e4",
          d: "M123.92,200.66c.19-.08,123.66,68.54,123.66,68.54s0,71.69.07,106.76c.2,11.29-.07,34.11-.07,34.11-41.27-22.78-82.32-45.76-123.6-68.54l-.07-.31V200.66Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#794387",
          d: "M0,272.81c41.35-24,82.52-48.23,123.89-72.15q-.06,70.29,0,140.56c-6.35-2.65-12.13-6.43-18.19-9.64C70.5,312.21,35.38,292.39,0,273.21v-.4Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#f1f1f1",
          d: "M371.39,200.76v86.18c-.13,18.27.46,36.47,0,54.73-41.21,22.4-123.82,68.4-123.82,68.4s.27-22.82.07-34.11c0-35.07-.07-106.76-.07-106.76Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#4c3683",
          d: "M371.39,200.76s123.88,72.14,123.88,72.31c-41.17,22.82-123.87,68.6-123.87,68.6h0c.46-18.26-.13-36.46,0-54.73V200.76Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#4c3683",
          d: "M0,273.21c35.36,19.21,70.43,39,105.7,58.4,6.06,3.21,11.84,7,18.19,9.64l.07.31Q67,374.79,10,408.05C6,402,4,394.86,2.53,387.8.14,376,0,364,0,352v-78.8Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#e74b50",
          d: "M495.27,273.07c.06,27.65,0,55.3,0,82.95.4,17.73-.12,36.62-9.57,52.25-34.2-20-114.33-66.6-114.33-66.6S454.1,295.89,495.27,273.07Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#0f68a0",
          d: "M10,408Q67,374.72,124,341.53c-.25,46.83.08,93.67-.16,140.49q-40.35-22.75-80.49-45.86C30.82,428.76,18,420.53,10,408Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#49bdca",
          d: "M124,341.53c41.28,22.78,82.33,45.76,123.6,68.54-41.2,24-123.7,72-123.76,71.95.24-46.82-.09-93.66.16-140.49Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#f6a925",
          d: "M371.2,341.76l.2-.09c.12,46.84-.25,93.69.19,140.53l-.31.15c-32.66-19.51-65.71-38.4-98.52-57.68-8.26-4.7-25.18-14.6-25.18-14.6S330,364.21,371.2,341.76Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#ef7414",
          d: "M371.4,341.67h0c3.65,2.49,80.13,46.62,114.33,66.6-4.86,8.3-12.56,14.39-20.32,19.84-12.52,8.42-25.88,15.47-38.9,23.05-18.31,10.35-36.5,20.91-54.92,31-.44-46.84-.07-93.69-.19-140.53Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#7db425",
          d: "M247.58,410.07V543c-10.25-1.51-21.42-3.87-30.5-9.08C186,516.66,154.81,499.5,123.82,482,165,458,206.38,434.12,247.58,410.07Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#b8ce01",
          d: "M247.58,410.07s16.92,9.9,25.18,14.6c32.81,19.28,65.86,38.17,98.52,57.68q-47.79,26.44-95.57,53A62.47,62.47,0,0,1,249.84,543h-2.26Z",
        },
      },
      {
        tag: "path",
        attr: {
          fill: "#f1f1f1",
          d: "M602.83,233.66",
        },
      },
    ],
  })(props);
}
