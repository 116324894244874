import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

const EMOJIS = [
  "😆",
  "👍",
  "❤️",
  "😅",
  "🤣",
  "😂",
  "😍",
  "🥰",
  "😘",
  "😛",
  "😝",
  "🤗",
  "😊",
  "🤫",
  "🤔",
  "😒",
  "🤥",
  "😴",
  "🤮",
  "🤯",
  "😎",
  "☹️",
  "😮",
  "🥺",
  "😧",
  "😥",
  "😢",
  "😭",
  "😱",
  "😞",
  "😫",
  "😠",
  "😡",
  "🙌",
];

export default function EmojisDrawer({
  title,
  isOpen = false,
  onClose = () => {},
  onSelect = () => {},
}) {
  const drawerPlacement = useBreakpointValue(
    {
      base: "bottom",
      lg: "right",
    },
    { fallback: "base" }
  );

  return (
    <Drawer
      size="lg"
      placement={drawerPlacement}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title || "لیست ایموجی ها"}</DrawerHeader>

        <DrawerBody>
          <HStack
            spacing="0"
            justifyContent="space-evenly"
            flexWrap="wrap"
            mb="1rem"
          >
            {EMOJIS.map((emoji, index) => (
              <Text
                key={`EmojiItem-${index}`}
                cursor="pointer"
                p=".25rem .35rem 0 .35rem"
                borderRadius="md"
                fontSize={{
                  base: "3xl",
                }}
                _hover={{ bgColor: "var(--c--surface)" }}
                onClick={() => onSelect(emoji)}
              >
                {emoji}
              </Text>
            ))}
          </HStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
