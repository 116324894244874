import React from "react";
import { ScaleFade, VStack, Text, Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export default function NotFoundPage({}) {
  return (
    <ScaleFade initialScale={0.1} in>
      <VStack minH={300} justify="center">
        <lord-icon
          src="https://cdn.lordicon.com/ybaojceo.json"
          trigger="hover"
          style={{ width: "150px", height: "150px" }}
        ></lord-icon>
        <Text>باعرض پوزش خطایی اتفاق افتاد!</Text>
        <Button as={RouterLink} to="/">
          بازگشت به صفحه اصلی
        </Button>
      </VStack>
    </ScaleFade>
  );
}
