import { Heading, Flex, Badge, Box, HStack } from "@chakra-ui/react";

export default function EventDatesListHeader({
  participants = null,
  isOnline = true,
}) {
  return (
    <Flex
      w="100%"
      mt="2.5rem"
      mb="1rem"
      flexDirection={{ base: "column-reverse", md: "row" }}
      alignItems={{ base: "flex-start", md: "center" }}
      justifyContent={{ base: "flex-start", md: "space-between" }}
      gap={{ base: "1.5rem", md: undefined }}
    >
      <Heading as="h1" size="sm">
        وضعیت حضور در پویش
      </Heading>

      {participants !== null ? (
        <Flex
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          gap=".5rem"
        >
          <Badge
            p=".2rem .5rem"
            fontSize="sm"
            borderRadius={4}
            colorScheme="green"
          >
            <HStack spacing=".5rem">
              <Box
                w=".63rem"
                h=".63rem"
                borderRadius="100%"
                bg="green"
                animation={
                  isOnline
                    ? "pulse_sm_green_anim 1s ease-out infinite"
                    : undefined
                }
              />

              <span>{`آنلاین: ${participants.online} نفر`}</span>
            </HStack>
          </Badge>

          <Badge
            p=".2rem .5rem"
            fontSize="sm"
            borderRadius={4}
            colorScheme="red"
          >
            <HStack spacing=".5rem">
              <Box
                w=".63rem"
                h=".63rem"
                borderRadius="100%"
                bg="red"
                animation={
                  isOnline
                    ? undefined
                    : "pulse_sm_red_anim 1s ease-out infinite"
                }
              />

              <span>{`آفلاین: ${participants.offline} نفر`}</span>
            </HStack>
          </Badge>

          <Badge
            p=".2rem .5rem"
            fontSize="sm"
            borderRadius={4}
            colorScheme="gray"
          >
            <HStack spacing=".5rem">
              <span>{`کل: ${participants.total} نفر`}</span>
            </HStack>
          </Badge>
        </Flex>
      ) : null}
    </Flex>
  );
}
