import { useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../../../hooks/useAuthContext";
import { AxiosError } from "axios";
import JoinEventButton from "../../../../components/JoinEventButton";
import PageHeader from "../../../../components/PageHeader";
import { parseEventData } from "../../../../utils/date";
import {
  ScaleFade,
  Flex,
  Text,
  Heading,
  Button,
  Box,
  Skeleton,
  Tag,
  Divider,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CommentsList from "../../../../components/CommentsList";
import useDefaultToast from "../../../../hooks/useDefaultToast";
import useEventStore, { EventTimeInfo } from "../../../../hooks/useEventStore";
import CountEventHeader from "../../../../components/EventDatesListHeader/CountEventHeader";
import NumberInputPicker from "../../../../components/CustomNumberInput/NumberInputPicker";

export default function CountEventDetail() {
  const { eventId } = useParams();
  const { authorizedAxios, checkForInvalidToken } = useAuth();
  const showToast = useDefaultToast();
  const [searchParams] = useSearchParams();
  const [count, setCount] = useState(0);
  const [customCount, setCustomCount] = useState(0);
  const eventStore = useEventStore();
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customCountHasError, setCustomCountErrorState] = useState(false);

  /** Manages get event information from the server */
  const getEventQuery = useQuery({
    queryKey: ["GetEventQuery", { eventId }],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    queryFn: async () => {
      const [
        eventInfoRes,
        eventParticipationsRes,
        currentTimeRes,
        participantsRes,
      ] = await Promise.all([
        authorizedAxios.get(`/count_based_event/${eventId}/`),
        authorizedAxios.get(`/c_participation/user/${eventId}/`),
        authorizedAxios.get(`/time/`),
        authorizedAxios.get(`/c_participation/stat/${eventId}/`),
      ]);

      const isPublicEvent =
        eventInfoRes.data.data.publication_state === "public";

      return {
        time: currentTimeRes.data.data,
        isPublicEvent,
        participants: participantsRes.data.data,
        event: {
          ...eventInfoRes.data.data,
          ...eventParticipationsRes.data.data,
        },
      };
    },
    onError: (e) => {
      if (checkForInvalidToken(e)) return;
      //unhandled error
      showToast({
        title: "در دریافت پویش خطایی روی داد!",
        description:
          "باعرض پوزش در دریافت پویش خطایی روی داد از اینکه شکیبایی می کنید متشکریم.",
        id: "GetEventQuery_UNHANDLED_ERROR",
        status: "error",
      });
    },
  });

  const participationMutation = useMutation({
    mutationKey: ["participationMutation"],
    retry: 0,
    mutationFn: async (addCount: number) => {
      setShowCustomInput(false);
      setCustomCount(0);

      const res = await authorizedAxios.patch(`/c_participation/${eventId}/`, {
        count: addCount,
      });

      return res?.data;
    },
    onSuccess: () => {
      // queryClient.invalidateQueries(["GetEventQuery", { eventId }]);
      // changeCount(addCount);

      showToast({
        title: "شمارش  شما ثبت شد 😊",
        status: "success",
        id: "countAdded",
      });
    },
    onError: (e: AxiosError<any>) => {
      if (checkForInvalidToken(e)) return;

      if (e?.response?.data?.message === "already done!") {
        showToast({
          title: "شمارش  شما قبلا ثبت شده است! 😊",
          status: "success",
          id: "countBeforeAdded",
        });
      } else {
        showToast({
          title: "خطایی رخ داد",
          description: "لطفا با پشتیبانی در تماس باشید",
          status: "error",
          id: "countAddedError",
        });
      }
    },
  });

  const paymentStatus = searchParams.get("ps");
  useEffect(() => {
    if (paymentStatus) {
      if (paymentStatus === "200") {
        showToast({
          title: "با سپاس فراوان، پرداخت با موفقیت انجام شد!",
          description: "اکنون شما یکی از حامیان خادمان امام زمان (عج) هستید.",
          status: "success",
          id: "paymentStatusSuccess",
        });
      } else {
        showToast({
          title: "متاسفانه، پرداخت با موفقیت انجام نشد!",
          description: "در صورت تمایل می توانید دوباره اقدام کنید.",
          status: "error",
          id: "paymentStatusError",
        });
      }
    }
  }, [paymentStatus]);

  let eventInfo: EventTimeInfo | null = null;
  let eventTitle = "";
  if (getEventQuery.isSuccess) {
    const event = getEventQuery.data.event;
    eventTitle = event.name || "";
    eventInfo = parseEventData(event, getEventQuery.data.time);
  }

  const participants =
    participationMutation?.data?.data || getEventQuery.data?.participants;

  const onButtonClick = () => {
    participationMutation.mutate(defaultCount);
  };

  const target = eventInfo?.eventData.target || 0;
  const userCount = participants?.user_participations_count || 0;
  const compensationTotal = participants?.user_compensations_count || 0;
  // const userCount = participants?.participations?.user_count || 0;
  const counted =
    parseInt(userCount) + count + customCount + parseInt(compensationTotal);

  const isFinish =
    !(getEventQuery.isLoading || getEventQuery.isFetching) &&
    userCount >= target;

  const notStart = (eventInfo?.untilStart ?? 1) > 0;

  const defaultCount = eventInfo?.eventData.default_count || 1;

  const isPublic = getEventQuery.data?.isPublicEvent || false;

  const {
    participated_users_count,
    total_compensations_count,
    total_participations_count,
  } = participants || {};

  return (
    <ScaleFade in={true}>
      <PageHeader
        //@ts-ignore
        title={
          <>
            {eventTitle}{" "}
            {isFinish && (
              <Tag mr={1} colorScheme="green">
                انجام شده
              </Tag>
            )}
          </>
        }
        isLoading={!eventInfo}
        drawerTitle="راهنمای صفحه پویش"
      >
        <Flex flexDirection="column" alignItems="stretch" gap="1rem">
          <Text textAlign="justify">
            در این بخش می توانید در کنار سایر خادمان امام زمان (عج)، در پویش های
            عمومی یا خصوصی شرکت کنید. دقت داشته باشید که پس از ایجاد پویش در
            اپلیکیشن خادمان، باید به صورت روزانه و در ساعات مشخص حضور خود را در
            اپلیکیشن به ثبت برسانید تا امتیاز خود را برای شرکت در قرعه کشی های
            خادمان افزایش دهید.
          </Text>

          <Heading fontSize="lg" mt=".5rem">
            ثبت حضور روزانه
          </Heading>
          <Text textAlign="justify">
            با کلیک روی دکمه بزرگ سبز رنگ، می توانید فرآیند ثبت حضور را انجام
            دهید. این فرآیند به صورت خودکار و با کلیک روی این دکمه انجام خواهد
            شد و نیاز به انجام هیچ کار دیگری از سمت شما وجود ندارد.{" "}
            <strong style={{ color: "var(--chakra-colors-blue-500)" }}>
              دقت داشته باشید که ثبت حضور فقط یکبار در طول روز قابل انجام است.
            </strong>{" "}
            پس از کلیک روی این دکمه، در صورت موفقیت آمیز بودن یا نبودن نتیجه
            فرآیند، یک پیغام به شما نمایش داده خواهد شد.
          </Text>
          <Text textAlign="justify">
            درصورت موفق بودن فرآیند، نمودار حضور شما در پویش به نمایش در می آید.
            همچنین اگر مرورگر شما از قابلیت دریافت اعلانات پشتیبانی کند، اجازه
            دریافت اعلانات از شما درخواست می شود که در صورت تمایل می توانید این
            اجازه را صادر بفرمایید. اگر این درخواست برای شما نمایش داده نشد ممکن
            است شما قبلا این اجازه را صادر کرده اید یا اینکه به دلیل عدم
            پشتیبانی مرورگر شما از این قابلیت باشد، که در مورد دوم پیشنهاد ما
            استفاده از آخرین نسخه ی مرورگر کروم (Chrome) است.
          </Text>

          <Heading fontSize="lg" mt=".5rem">
            بررسی وضعیت حضور در پویش
          </Heading>
          <Text textAlign="justify">
            در انتهای این صفحه، لیستی از روزهای پویش نمایش داده می شود، که
            بیانگر وضعیت حضور شما در پویش می باشد. در این لیست روزهایی که حضور
            شما ثبت شده باشد به رنگ سبز، روزهایی که شرکت نکرده اید به رنگ نارنجی
            و روزهای باقیمانده هم به رنگ خاکستری در می آیند. در پایین این لیست
            هم تعداد روزها براساس وضعیت آنها (حضور یافته، عدم حضور و باقیمانده)
            مشخص شده است.
          </Text>

          {/* <Heading fontSize="lg" mt=".5rem">
              بستن چله فعلی
            </Heading>
            <Text textAlign="justify">
              اگر به هر دلیلی امکان ثبت حضور مرتب در اپلیکشن برای شما فراهم نشد،
              می توانید چله مورد نظر خود را ببندید و چله جدید دیگری را آغاز
              کنید.{" "}
              <strong style={{ color: "var(--chakra-colors-red-500)" }}>
                دقت داشته باشید که اطلاعات چله قبلی از بین نخواهد رفت!
              </strong>
            </Text> */}
        </Flex>
      </PageHeader>
      {true ? (
        <Box position={"relative"}>
          <JoinEventButton
            eventInfo={isFinish ? null : eventInfo}
            eventType={"count_based_event"}
            eventId={eventId}
            isDisabled={getEventQuery.data?.event.is_over}
            isFinish={isFinish}
            isLoading={participationMutation.isLoading}
            onButtonClick={onButtonClick}
            tooltipText={
              !showCustomInput
                ? getEventQuery.data?.event.is_over
                  ? "متاسفانه پویش تمام شده است"
                  : "برای شمارش لمس کنید"
                : "تعداد دلخواه را وارد کرده و دکمه ثبت را بزنید"
            }
          />

          {showCustomInput && (
            <Flex
              position={"absolute"}
              flexDirection={"column"}
              top={"-48px"}
              bottom={"-32px"}
              right={0}
              left={0}
              backdropFilter={"blur(8px)"}
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <NumberInputPicker
                title={""}
                onChange={(v) => {
                  if (+v > 50) {
                    showToast({
                      title: "عدد وارد شده بزرگ است!",
                      description: "امکان افزودن بیش از ۵۰ وجود ندارد",
                      id: "MAX_NUMBER",
                      status: "warning",
                    });
                    v = "50";
                    setCustomCountErrorState(true);
                  }
                  setCustomCount(+v);
                }}
                //@ts-ignore
                min={1}
                defaultValue={defaultCount}
                isLoading={participationMutation.isLoading}
                inputBgColor="#ffffffaa"
                max={50}
                onBlur={() => setCustomCountErrorState(false)}
                placeHolder="تعداد مورد نظر را وارد نمایید"
              />

              {customCountHasError ? (
                <Tag colorScheme="red">امکان افزودن بیش از ۵۰ وجود ندارد.</Tag>
              ) : null}

              <Flex gap={2} w={"100%"}>
                <Button
                  mt={2}
                  flex={1}
                  colorScheme="telegram"
                  disabled={!count}
                  isLoading={participationMutation.isLoading}
                  onClick={() => participationMutation.mutate(customCount)}
                >
                  ثبت تعداد وارد شده
                </Button>

                <Button
                  mt={2}
                  colorScheme="red"
                  p={5}
                  onClick={() => {
                    setShowCustomInput(false);
                    setCustomCount(0);
                  }}
                >
                  بستن
                </Button>
              </Flex>
            </Flex>
          )}
        </Box>
      ) : (
        <Text textAlign={"center"}>پویش تکمیل شده است</Text>
      )}
      <Skeleton isLoaded={!getEventQuery.isLoading}>
        {eventInfo !== null &&
        eventInfo.untilStart <= 0 &&
        !(getEventQuery.isLoading || getEventQuery.isFetching) ? (
          <>
            <Text as="h4" size="sm">
              آمار شما در پویش
            </Text>
            <CountEventHeader
              eventInfo={eventInfo}
              isLoading={participationMutation.isLoading}
              participants={{
                total: parseInt(userCount) + parseInt(compensationTotal),
                count: counted,
                remain: parseInt(target) - counted,
                target: parseInt(target),
              }}
              text={{
                total: "کل",
                count: "تعداد",
                remain: "مانده",
                target: "هدف",
              }}
              isPublic={isPublic}
              isFinish={isFinish}
            />

            <Text as="h4" size="sm">
              آمار کل پویش
            </Text>
            <CountEventHeader
              eventInfo={eventInfo}
              isLoading={participationMutation.isLoading}
              participants={{
                total: parseInt(userCount) + parseInt(compensationTotal),
                count: parseInt(participated_users_count),
                remain: parseInt(total_compensations_count),
                target: parseInt(total_participations_count),
              }}
              text={{
                total: "",
                count: "تعدادکاربران",
                remain: "جبران‌شده",
                target: "تعداد کل",
              }}
              isPublic={isPublic}
              isFinish={isFinish}
            />
          </>
        ) : null}

        {!isFinish && !notStart && (
          <Flex>
            <Button
              mt={4}
              colorScheme="telegram"
              variant={"outline"} //'solid' | 'outline' | 'wacky' | 'chill'
              m="auto"
              onClick={() => {
                setShowCustomInput((custom) => !custom);
                setCustomCount(defaultCount);
              }}
            >
              افزودن تعداد دلخواه
            </Button>
          </Flex>
        )}

        <Stack
          direction="row"
          mt={8}
          justifyContent={"center"}
          alignItems={"center"}
          p={2}
        >
          <Heading as="h1" size="sm">
            توضیحات پویش
          </Heading>
          <Divider borderStyle={"dashed"} width={"auto"} flex={1} mr={2} />
        </Stack>

        <Text my={4} px={2}>
          {eventInfo?.eventData?.description}
        </Text>
        {!getEventQuery.data?.isPublicEvent && <Box height={"64px"} />}
      </Skeleton>

      {/* <EventDatesList
        isLoading={getEventQuery.isLoading}
        isFetching={getEventQuery.isFetching}
        isError={getEventQuery.isError}
        //@ts-ignore
        eventInfo={eventInfo}
        isParticipate={undefined}
      /> */}

      {(getEventQuery.data ? getEventQuery.data.isPublicEvent : false) ? (
        <CommentsList
          //@ts-ignore
          eventId={eventId}
          mainVStackProps={{ mb: { base: "5rem", lg: "0" } }}
        />
      ) : null}
    </ScaleFade>
  );
}
