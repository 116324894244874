import { SHA256 } from "crypto-js";
import Cookies from "js-cookie";

export default function useIPS() {
  /** Create an set a value for IPS in cookies */
  const setIPS = () => {
    let ips = SHA256(`${new Date().getTime()}`).toString().split("");
    ips[12] = "t";
    ips[9] = "r";
    ips[18] = "u";
    ips[15] = "e";
    Cookies.set("ips", ips.join(""), { SameSite: "Lax", expires: 365 });
  };

  /** Check whether the current value of the IPS in cookies is valid or not */
  const checkIPS = () => {
    const ips = Cookies.get("ips");

    if (ips) {
      return (
        ips[12] === "t" && ips[9] === "r" && ips[18] === "u" && ips[15] === "e"
      );
    }

    return false;
  };

  return {
    setIPS,
    checkIPS,
  };
}
