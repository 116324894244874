import { useMutation, useQueryClient } from "react-query";
import ReadMoreText from "../ReadMoreText";
import useForceUpdate from "../../hooks/forceUpdate";
import axios from "axios";
import { useAuth } from "../../hooks/useAuthContext";
import EmojisDrawer from "../EmojisDrawer";
import {
  RiAddFill,
  RiDeleteBin2Fill,
  RiEdit2Fill,
  RiEmojiStickerLine,
  RiMore2Fill,
} from "react-icons/ri";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Tag,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CommentInfoDrawer from "../CommentInfoDrawer";

export default function CommentItem({ comment }) {
  const { BASE_URL, token, sid, checkForInvalidToken } = useAuth();
  const toast = useToast();
  const forceUpdate = useForceUpdate();
  const queryClient = useQueryClient();
  const emojiDrawerDisclosuer = useDisclosure();
  const editCommentDrawerDisclosure = useDisclosure();
  const confirmRemoveCommentModalDisclosure = useDisclosure();

  const saveReactionMutation = useMutation({
    mutationKey: ["saveReactionMutation"],
    retry: 0,
    mutationFn: async ({ emoji }) => {
      const res = await axios.patch(
        `${BASE_URL}/comment/${comment.id}/`,
        {
          emoji: emoji,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            sid: sid,
          },
        }
      );

      return res.data.data.reactions;
    },
    onSuccess: (allReactions) => {
      comment.reactions = allReactions;
      forceUpdate();
    },
    onError: (e) => {
      if (checkForInvalidToken(e)) return;

      toast({
        title: `متاسفانه واکنش شما ثبت نشد!`,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  const removeCommentMutation = useMutation({
    mutationKey: ["removeCommentMutation"],
    retry: 0,
    mutationFn: async () => {
      await axios.delete(`${BASE_URL}/comment/${comment.id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          sid: sid,
        },
      });
    },
    onSuccess: () => {
      confirmRemoveCommentModalDisclosure.onClose();
      queryClient.invalidateQueries(["getCommentsInfiniteQuery"]);

      toast({
        title: `نظر با موفقیت حذف شد.`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    },
    onError: (e) => {
      if (checkForInvalidToken(e)) return;

      toast({
        title: `متاسفانه نظر مورد نظر حذف نشد!`,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  return (
    <>
      <Card w="21.5rem" mx="0.5rem">
        <CardHeader
          w="100%"
          as={HStack}
          justifyContent="space-between"
          spacing=".75rem"
          p={comment.is_owned ? ".75rem .75rem .75rem 0" : ".75rem"}
          borderBottom="1px solid var(--c--outline)"
        >
          <Avatar size="md" src={comment.avatar} name={comment.name} />

          <VStack flex="1" alignItems="stretch" spacing="0">
            <Text fontSize="sm" fontWeight="bolder">
              {comment.name || "یکی از خادمان امام زمان (عج)"}
            </Text>

            <Text fontSize="sm" color="var(--c--subtitle)">
              {new Date(comment.datetime).toLocaleDateString("fa", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour12: false,
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Text>
          </VStack>

          {comment.is_owned ? (
            <Popover placement="bottom-end">
              <PopoverTrigger>
                <IconButton icon={<RiMore2Fill />} variant="text" />
              </PopoverTrigger>

              <PopoverContent w="12rem">
                <PopoverArrow />
                <PopoverBody>
                  <Button
                    w="100%"
                    variant="ghost"
                    colorScheme="blue"
                    justifyContent="flex-start"
                    leftIcon={<RiEdit2Fill />}
                    onClick={() => editCommentDrawerDisclosure.onOpen()}
                  >
                    ویرایش نظر
                  </Button>

                  <Button
                    w="100%"
                    variant="ghost"
                    colorScheme="red"
                    justifyContent="flex-start"
                    leftIcon={<RiDeleteBin2Fill />}
                    onClick={() => confirmRemoveCommentModalDisclosure.onOpen()}
                  >
                    حذف نظر
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ) : null}
        </CardHeader>

        <CardBody bgColor="var(--c--surface)">
          <ReadMoreText style={{ fontSize: "sm", textAlign: "justify" }}>
            {comment.text}
          </ReadMoreText>
        </CardBody>

        <CardFooter
          w="100%"
          as={HStack}
          flexWrap="wrap"
          spacing=".39rem"
          p=".75rem"
          borderTop="1px solid var(--c--outline)"
          opacity={saveReactionMutation.isLoading ? ".36" : "1"}
          pointerEvents={saveReactionMutation.isLoading ? "none" : "all"}
        >
          {Object.entries(comment.reactions).map((reaction, index) => {
            return (
              <Tag
                key={`CommentReaction-${comment.id}-${index}`}
                colorScheme={reaction[1][1] ? "telegram" : "gray"}
                variant={reaction[1][1] ? "solid" : "subtle"}
                borderRadius="full"
                p=".25rem .5rem .15rem .25rem"
                gap=".25rem"
                cursor="pointer"
                onClick={() => {
                  saveReactionMutation.mutate({ emoji: reaction[0] });
                }}
              >
                {reaction[1][0]}
                <Text fontSize="lg">{reaction[0]}</Text>
              </Tag>
            );
          })}

          <Tag
            variant="outline"
            borderRadius="full"
            colorScheme="gray"
            p=".23rem .35rem"
            gap=".25rem"
            cursor="pointer"
            onClick={() => emojiDrawerDisclosuer.onOpen()}
          >
            <Icon as={RiAddFill} />
            <Icon fontSize="xl" as={RiEmojiStickerLine} />
          </Tag>
        </CardFooter>
      </Card>

      <CommentInfoDrawer
        eventId={comment.event_id}
        comment={comment}
        isOpen={editCommentDrawerDisclosure.isOpen}
        onClose={editCommentDrawerDisclosure.onClose}
      />

      <EmojisDrawer
        isOpen={emojiDrawerDisclosuer.isOpen}
        onClose={emojiDrawerDisclosuer.onClose}
        onSelect={(emoji) => {
          emojiDrawerDisclosuer.onClose();
          saveReactionMutation.mutate({ emoji });
        }}
      />

      <Modal
        isOpen={confirmRemoveCommentModalDisclosure.isOpen}
        onClose={confirmRemoveCommentModalDisclosure.onClose}
        closeOnEsc={!removeCommentMutation.isLoading}
        closeOnOverlayClick={!removeCommentMutation.isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>حذف نظر</ModalHeader>

          <ModalBody>
            با حذف این نظر تمام اطلاعات آن از دست خواهد رفت، آیا از حذف آن مطمئن
            هستید؟
          </ModalBody>

          <ModalFooter as={HStack}>
            <Button
              variant="outline"
              isDisabled={removeCommentMutation.isLoading}
              onClick={() => confirmRemoveCommentModalDisclosure.onClose()}
            >
              منصرف شدم
            </Button>

            <Button
              colorScheme="red"
              isLoading={removeCommentMutation.isLoading}
              onClick={() => removeCommentMutation.mutate()}
            >
              حذف نظر
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export function CommentSkeleton() {
  return (
    <Card w="22.5rem" mx="0.5rem">
      <CardHeader
        w="100%"
        as={HStack}
        justifyContent="space-between"
        spacing=".75rem"
        p=".75rem"
        borderBottom="1px solid var(--c--outline)"
      >
        <SkeletonCircle w="3rem" h="3rem" />

        <VStack flex="1" alignItems="stretch" spacing=".25rem">
          <Skeleton w="9rem" h="1.3rem" borderRadius="md" />
          <Skeleton w="6rem" h="1.3rem" borderRadius="md" />
        </VStack>
      </CardHeader>

      <CardBody bgColor="var(--c--surface)">
        <SkeletonText w="100%" skeletonHeight="2" noOfLines={4} />
      </CardBody>

      <CardFooter
        w="100%"
        as={HStack}
        flexWrap="wrap"
        spacing=".39rem"
        p=".75rem"
        borderTop="1px solid var(--c--outline)"
      >
        <Skeleton w="3rem" h="1.5rem" borderRadius="full" />
        <Skeleton w="3rem" h="1.5rem" borderRadius="full" />
        <Skeleton w="3rem" h="1.5rem" borderRadius="full" />
      </CardFooter>
    </Card>
  );
}
