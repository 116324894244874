import { SHA256 } from "crypto-js";
import Cookies from "js-cookie";

export default function useIPC() {
  /** Create an set a value for IPC in cookies */
  const setIPC = () => {
    let ipc = SHA256(`${new Date().getTime()}`).toString().split("");
    ipc[9] = "t";
    ipc[12] = "r";
    ipc[15] = "u";
    ipc[18] = "e";
    Cookies.set("ipc", ipc.join(""), { SameSite: "Lax", expires: 365 });
  };

  /** Check whether the current value of the IPC in cookies is valid or not */
  const checkIPC = () => {
    const ipc = Cookies.get("ipc");

    if (ipc) {
      return (
        ipc[9] === "t" && ipc[12] === "r" && ipc[15] === "u" && ipc[18] === "e"
      );
    }

    return false;
  };

  return {
    setIPC,
    checkIPC,
  };
}
