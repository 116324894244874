import { create } from "zustand";
import { Day_Based_Event, User } from "../types/apiTypes";
import { useDisclosure } from "@chakra-ui/react";

//todo add permission or others...
interface VIPStore {
  isVip: Boolean;
  profile: User | null;
  lastDayBaseEvent: Day_Based_Event | null;
  profileSetListeners: Array<(user: User) => void>;
  setVip: (vip: Boolean) => void;
  setProfile: (user: User) => void;
  setLastDayBaseEvent: (event: Day_Based_Event) => void;
}

interface UseVIP extends VIPStore {
  filterVip: <T>(data: T) => T;
  profileModalDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
}

export const useVipStore = create<VIPStore>((set) => ({
  isVip: false,
  profile: null,
  profileSetListeners: [],
  setVip: (vip) => set(() => ({ isVip: vip })),
  setProfile: (user) => set(() => ({ profile: user })),

  lastDayBaseEvent: null,
  setLastDayBaseEvent: (event) => set(() => ({ lastDayBaseEvent: event })),
}));

const useVIP = (): UseVIP => {
  const vipStore = useVipStore();
  const profileModalDisclosure = useDisclosure();

  const { isVip } = vipStore;
  const filterVip = (data: any) => {
    if (isVip) return data;

    if (data instanceof Array) {
      return data?.filter((r) => !r.vip);
    }
  };
  return { ...vipStore, filterVip, profileModalDisclosure };
};

export default useVIP;
