import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "../../hooks/useAuthContext";
import Cookies from "js-cookie";
import axios from "axios";
import { requestNotificationPermission } from "../../firebase/firebase-messaging";
import { PieChart } from "react-minimal-pie-chart";
import confetti from "canvas-confetti";
import FarajVector from "../Vectors/Faraj";
import CountDownTimer from "../CountDownTimer";
import {
  Button,
  Tooltip,
  Box,
  Card,
  useToast,
  VStack,
  Tag,
  ScaleFade,
} from "@chakra-ui/react";
import useEventStore from "../../hooks/useEventStore";
import { Link } from "react-router-dom";
import { RiNotification2Fill, RiShakeHandsFill } from "react-icons/ri";

const USER_DEVICE_TOKEN_SAVE_KEY = "isDeviceTokenSaved";

export default function JoinEventButton({
  eventType,
  eventId,
  eventInfo,
  isDisabled = false,
  isLoading = false,
  isFinish = false,
  onButtonClick,
  tooltipText = "برای شمارش لمس کنید",
}) {
  const { token, sid, BASE_URL, checkForInvalidToken, authorizedAxios } =
    useAuth();
  const queryClient = useQueryClient();
  const toast = useToast();
  const farajSoundRef = useRef(null);
  const [isToastDisplayed, setToastDisplayState] = useState(false);
  const [isButtonClicked, setButtonClickState] = useState(false);

  const eventStore = useEventStore();
  useEffect(() => {
    eventStore.setPLay(() => {
      setTimeout(() => {
        confetti({
          particleCount: 270,
          spread: 120,
          origin: { y: 0.6 },
        });

        if (farajSoundRef.current) {
          farajSoundRef.current.play();
        }
      }, 222);
    });
  }, []);

  function showSuccessfulToast() {
    confetti({
      particleCount: 270,
      spread: 120,
      origin: { y: 0.6 },
    });

    if (farajSoundRef.current) {
      farajSoundRef.current.play();
    } else {
      console.log("مرورگر شما پخش صوت را پشتیبانی نمی کند!");
    }

    setToastDisplayState(true);

    setTimeout(() => setToastDisplayState(false), 8000);
  }

  /** Manages the device token registration request of the user's device on the server */
  const saveUserDeviceTokenMutation = useMutation({
    mutationKey: ["saveUserDeviceTokenMutation", { eventId }],
    retry: 0,
    mutationFn: async () => {
      /** Requests permission from the user to receive the notification and get device token if permission is granted */
      const userDeviceToken = await requestNotificationPermission();

      /** Checks if device token is available or not */
      if (userDeviceToken) {
        /** Saves the device token on the server */
        await axios.put(
          `${BASE_URL}/user/dev/`,
          {
            device_token: userDeviceToken,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Sid: sid,
            },
          }
        );
      } else {
        throw new Error("User device token not available :(");
      }
    },
    onSuccess: () => {
      /**
       * A cookie is created to determine the token registration status of the user's current device on the server
       * This cookie is created to prevent re-registration of the device token on the server.
       */
      Cookies.set(USER_DEVICE_TOKEN_SAVE_KEY, "YES", { expires: 365 });
    },
    onError: (e) => {
      if (checkForInvalidToken(e)) return;

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  const registerTodayAttendanceMutation = useMutation({
    mutationKey: ["registerTodayAttendanceMutation"],
    retry: 0,
    mutationFn: async () => {
      const res =
        eventType === "count_based_event"
          ? authorizedAxios.patch(`${BASE_URL}/c_participation/${eventId}/`, {
              count: 1,
            })
          : await authorizedAxios.patch(
              `/participation/${eventType}/${eventId}/`,
              {}
            );

      return res.data.data;
    },
    onSuccess: () => {
      setButtonClickState(true);
      queryClient.invalidateQueries(["GetEventQuery", { eventId }]);
      showSuccessfulToast();
    },
    onError: (e) => {
      if (checkForInvalidToken(e)) return;

      if (e.response.data.message === "already done!") {
        toast({
          title: "حضور امروز شما قبلا ثبت شده است! 😊",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: "خطایی رخ داد",
          description: "لطفا با پشتیبانی در تماس باشید",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }

      // toast({
      //   title: `کد خطا: ${e.response.status}`,
      //   description: JSON.stringify(e.response.data, null, 2),
      //   status: "error",
      //   duration: 4000,
      //   isClosable: true,
      // });
    },
  });

  const isAttendToday = eventInfo ? eventInfo.isAttendToday : false;

  if (eventInfo !== null) {
    if (eventInfo.untilStart > 0) {
      return (
        <VStack
          position="relative"
          minH="12rem"
          alignItems="center"
          justifyContent="center"
          mt="3rem"
          mb="5rem"
        >
          <Button
            position="absolute"
            colorScheme="green"
            w="12rem"
            h="12rem"
            borderRadius="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            animation="light_blur_anim .3s forwards"
            isDisabled
            isLoading={isLoading}
          >
            <svg
              height="6rem"
              width="6rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 232.395 232.395"
            >
              <path
                style={{ fill: "var(--chakra-colors-chakra-body-bg)" }}
                d="M177.121,83.258c-5.921,0-11.324,2.26-15.393,5.962c-4.068-3.702-9.472-5.962-15.393-5.962 c-5.925,0-11.333,2.261-15.404,5.963c-1.672-1.522-3.579-2.783-5.645-3.76c7.467-9.163,11.828-20.792,11.828-33.095 C137.115,23.491,113.627,0,84.756,0C55.878,0,32.383,23.491,32.383,52.366c0,20.105,11.649,38.4,29.469,47.085v63.871 c0,38.086,30.988,69.072,69.077,69.072c38.092,0,69.083-30.986,69.083-69.072V106.15C200.012,93.527,189.743,83.258,177.121,83.258z M47.383,52.366C47.383,31.762,64.148,15,84.756,15c20.6,0,37.359,16.762,37.359,37.366c0,11.702-5.529,22.549-14.467,29.515V52.366 c0-12.628-10.27-22.902-22.893-22.902c-12.629,0-22.904,10.274-22.904,22.902v29.513C52.912,74.915,47.383,64.068,47.383,52.366z M185.012,163.322c0,29.815-24.262,54.072-54.083,54.072c-29.818,0-54.077-24.257-54.077-54.072V94.887 c0.013-0.251,0.013-0.502,0-0.751v-41.77c0-4.357,3.546-7.902,7.904-7.902c4.352,0,7.893,3.545,7.893,7.902v53.784v16.451 c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.54-7.893,7.891-7.893c4.351,0,7.89,3.541,7.89,7.893v16.451 c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.547-7.893,7.907-7.893c4.352,0,7.893,3.541,7.893,7.893 v16.451c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.541-7.893,7.893-7.893 c4.351,0,7.891,3.541,7.891,7.893V163.322z"
              />
            </svg>
          </Button>

          <VStack
            as={ScaleFade}
            alignItems="center"
            justifyContent="center"
            spacing="1.5rem"
            in
          >
            <Tag
              fontSize={{
                base: "md",
                md: "lg",
              }}
              fontWeight="bolder"
              colorScheme="red"
              variant="subtle"
              p=".25rem .75rem"
              zIndex="999"
            >
              زمان باقیمانده تا آغاز پویش
            </Tag>

            <CountDownTimer
              msDifference={eventInfo.untilStart}
              onEnd={() => window.location.reload()}
              zIndex="999"
              dotsProps={{ display: "none" }}
              numberProps={{ py: "1.5rem", bgColor: "var(--c--success)" }}
            />

            {eventInfo.eventData.pre_registration_link ? (
              <Button
                as={Link}
                to={eventInfo.eventData.pre_registration_link}
                colorScheme="red"
                leftIcon={<RiNotification2Fill />}
              >
                برای اطلاع از شروع پویش، کلیک کنید
              </Button>
            ) : null}
          </VStack>
        </VStack>
      );
    }
  }

  if (isFinish) {
    return (
      <VStack
        position="relative"
        minH="12rem"
        alignItems="center"
        justifyContent="center"
        mt="3rem"
        mb="5rem"
      >
        <Button
          position="absolute"
          colorScheme="green"
          w="12rem"
          h="12rem"
          borderRadius="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          animation="light_blur_anim .3s forwards"
          isDisabled
          isLoading={isLoading}
        >
          <svg
            height="6rem"
            width="6rem"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 232.395 232.395"
          >
            <path
              style={{ fill: "var(--chakra-colors-chakra-body-bg)" }}
              d="M177.121,83.258c-5.921,0-11.324,2.26-15.393,5.962c-4.068-3.702-9.472-5.962-15.393-5.962 c-5.925,0-11.333,2.261-15.404,5.963c-1.672-1.522-3.579-2.783-5.645-3.76c7.467-9.163,11.828-20.792,11.828-33.095 C137.115,23.491,113.627,0,84.756,0C55.878,0,32.383,23.491,32.383,52.366c0,20.105,11.649,38.4,29.469,47.085v63.871 c0,38.086,30.988,69.072,69.077,69.072c38.092,0,69.083-30.986,69.083-69.072V106.15C200.012,93.527,189.743,83.258,177.121,83.258z M47.383,52.366C47.383,31.762,64.148,15,84.756,15c20.6,0,37.359,16.762,37.359,37.366c0,11.702-5.529,22.549-14.467,29.515V52.366 c0-12.628-10.27-22.902-22.893-22.902c-12.629,0-22.904,10.274-22.904,22.902v29.513C52.912,74.915,47.383,64.068,47.383,52.366z M185.012,163.322c0,29.815-24.262,54.072-54.083,54.072c-29.818,0-54.077-24.257-54.077-54.072V94.887 c0.013-0.251,0.013-0.502,0-0.751v-41.77c0-4.357,3.546-7.902,7.904-7.902c4.352,0,7.893,3.545,7.893,7.902v53.784v16.451 c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.54-7.893,7.891-7.893c4.351,0,7.89,3.541,7.89,7.893v16.451 c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.547-7.893,7.907-7.893c4.352,0,7.893,3.541,7.893,7.893 v16.451c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.541-7.893,7.893-7.893 c4.351,0,7.891,3.541,7.891,7.893V163.322z"
            />
          </svg>
        </Button>

        <VStack
          as={ScaleFade}
          alignItems="center"
          justifyContent="center"
          spacing="1.5rem"
          in
        >
          <Tag
            fontSize={{
              base: "md",
              md: "lg",
            }}
            fontWeight="bolder"
            colorScheme="red"
            variant="subtle"
            p=".25rem .75rem"
            zIndex="999"
          >
            پویش به اتمام رسیده است
          </Tag>

          {/* <CountDownTimer
            msDifference={eventInfo.untilStart}
            onEnd={() => window.location.reload()}
            zIndex="999"
            dotsProps={{ display: "none" }}
            numberProps={{ py: "1.5rem" }}
          /> */}
        </VStack>
      </VStack>
    );
  }

  /** The Tooltip text of the button is determined based on whether the registration time has passed or not */
  let tooltipProps = {
    label: "برای اعلام حضور لمس کنید.",
    bg: "gray.500",
  };

  /** If the button was clicked and the event information was recorded, it changes the Tooltip text. */
  if (isButtonClicked || isDisabled || isAttendToday) {
    tooltipProps = {
      label: "نمودار وضعیت حضور شما",
      bg: "gray.500",
    };
  }

  if (eventType === "count_based_event") {
    tooltipProps.label = tooltipText || "برای شمارش لمس کنید";
  }

  return (
    <>
      <audio ref={farajSoundRef} style={{ display: "none" }}>
        {eventType === "day_based_event" && (
          <source src="/sounds/faraj_sound.snd" type="audio/mpeg" />
        )}
        {eventType === "count_based_event" && (
          <source src="/sounds/salavat.mp3" type="audio/mp3" />
        )}
      </audio>

      <Tooltip
        placement="bottom"
        color="#FFFFFF"
        p=".5rem 1rem"
        fontSize="sm"
        hasArrow
        isOpen={
          !registerTodayAttendanceMutation.isLoading &&
          eventInfo !== null &&
          !isToastDisplayed
        }
        {...tooltipProps}
      >
        {isToastDisplayed ? (
          <Card
            w="12rem"
            h="12rem"
            mx="auto"
            mt="3rem"
            mb="5rem"
            borderRadius="100%"
            boxShadow="5px 5px 50px green"
            display="flex"
            alignItems="center"
            justifyContent="center"
            animation="fade_in .3s forwards"
          >
            <FarajVector />
          </Card>
        ) : eventType !== "count_based_event" &&
          (isButtonClicked || isDisabled || isAttendToday) &&
          eventInfo ? (
          <Box
            w="12rem"
            h="12rem"
            mx="auto"
            mt="3rem"
            mb="5rem"
            animation="fade_in .3s forwards"
          >
            <PieChart
              lineWidth="54"
              animate
              data={[
                {
                  title: "تعداد حضور",
                  value: eventInfo.attendedDaysNumber,
                  color: "var(--chakra-colors-green-500)",
                },
                {
                  title: "تعداد عدم حضور",
                  value: eventInfo.absentDaysNumber,
                  color: "var(--chakra-colors-orange-500)",
                },
                {
                  title: "باقیمانده",
                  value: eventInfo.remainDaysNumber,
                  color: "var(--chakra-colors-gray-300)",
                },
              ]}
            />
          </Box>
        ) : (
          <Button
            colorScheme="green"
            w="12rem"
            h="12rem"
            borderRadius="full"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mx="auto"
            mt="3rem"
            mb="5rem"
            isLoading={
              registerTodayAttendanceMutation.isLoading ||
              eventInfo === null ||
              isLoading
            }
            isDisabled={isDisabled || eventInfo === null}
            animation={
              registerTodayAttendanceMutation.isLoading || eventInfo === null
                ? undefined
                : "pulse_anim 2.5s ease-out infinite"
            }
            onClick={() => {
              if (eventType === "count_based_event") {
                return onButtonClick();
              }

              // joinEventMutation.mutate();
              registerTodayAttendanceMutation.mutate();

              /** If the device token of the user was not registered before, it will be registered. */
              //disable
              if (false && Cookies.get(USER_DEVICE_TOKEN_SAVE_KEY) !== "YES") {
                toast.promise(saveUserDeviceTokenMutation.mutateAsync(), {
                  loading: {
                    title: "درحال ثبت دستگاه در بین گیرندگان اعلانات",
                    description:
                      "برای دریافت اعلانات، لطفاً اجازه دریافت اعلانات را به اپلیکیشن خادمان بدهید!",
                    status: "info",
                    isClosable: false,
                  },
                  success: {
                    title: "اعلانات برای شما فعال شد!",
                    description:
                      "ازین پس  می توانید روی این دستگاه، اعلانات را دریافت کنید.",
                    status: "success",
                    isClosable: true,
                  },
                  error: {
                    title: "متاسفانه، اعلانات فعال نشد.",
                    description:
                      "دستگاه شما به لیست دریافت کنندگان اعلانات اضافه نشد. ممکن است مرورگر شما از این قابلیت پشتیبانی نکند، درصورت تمایل از آخرین نسخه\u200cی مرورگر کروم (Chrome) استفاده کنید.",
                    status: "error",
                    isClosable: true,
                  },
                });
              }
            }}
          >
            <svg
              height="6rem"
              width="6rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 232.395 232.395"
            >
              <path
                style={{ fill: "var(--chakra-colors-chakra-body-bg)" }}
                d="M177.121,83.258c-5.921,0-11.324,2.26-15.393,5.962c-4.068-3.702-9.472-5.962-15.393-5.962 c-5.925,0-11.333,2.261-15.404,5.963c-1.672-1.522-3.579-2.783-5.645-3.76c7.467-9.163,11.828-20.792,11.828-33.095 C137.115,23.491,113.627,0,84.756,0C55.878,0,32.383,23.491,32.383,52.366c0,20.105,11.649,38.4,29.469,47.085v63.871 c0,38.086,30.988,69.072,69.077,69.072c38.092,0,69.083-30.986,69.083-69.072V106.15C200.012,93.527,189.743,83.258,177.121,83.258z M47.383,52.366C47.383,31.762,64.148,15,84.756,15c20.6,0,37.359,16.762,37.359,37.366c0,11.702-5.529,22.549-14.467,29.515V52.366 c0-12.628-10.27-22.902-22.893-22.902c-12.629,0-22.904,10.274-22.904,22.902v29.513C52.912,74.915,47.383,64.068,47.383,52.366z M185.012,163.322c0,29.815-24.262,54.072-54.083,54.072c-29.818,0-54.077-24.257-54.077-54.072V94.887 c0.013-0.251,0.013-0.502,0-0.751v-41.77c0-4.357,3.546-7.902,7.904-7.902c4.352,0,7.893,3.545,7.893,7.902v53.784v16.451 c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.54-7.893,7.891-7.893c4.351,0,7.89,3.541,7.89,7.893v16.451 c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.547-7.893,7.907-7.893c4.352,0,7.893,3.541,7.893,7.893 v16.451c0,4.142,3.358,7.5,7.5,7.5c4.142,0,7.5-3.358,7.5-7.5V106.15c0-4.352,3.541-7.893,7.893-7.893 c4.351,0,7.891,3.541,7.891,7.893V163.322z"
              />
            </svg>
          </Button>
        )}
      </Tooltip>
    </>
  );
}
