import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuthContext";
import useIPC from "../../hooks/IPC";
import useIPS from "../../hooks/IPS";
import { useToast } from "@chakra-ui/react";
import useDefaultToast from "../../hooks/useDefaultToast";

export default function ProtectedRoute({
  element = <></>,
  checkAuth = true,
  checkProfile = true,
  checkPassword = true,
}) {
  const { checkIPC } = useIPC();
  const { checkIPS } = useIPS();
  const { isAuthenticated, token } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const showToast = useDefaultToast();

  useEffect(() => {
    /** Checking whether user is logged in or not */
    if (checkAuth && !isAuthenticated) {
      // Display error message to user

      if (token) {
        showToast({
          // title: "متاسفانه، اطلاعات ورود شما منقضی شده است!",
          title: "برای استفاده از این قسمت باید به حساب کاربری خود وارد شوید.",
          description: "لطفاً ‌دوباره وارد حساب خود شوید.",
          status: "error",
          id: "TOKEN_EXPIRE", //prevent dupplicate
        });
        localStorage.token = "";
      } else {
        showToast({
          title: "ورود به حساب الزامی است!",
          description:
            "برای دسترسی به امکانات اپلیکیشن باید وارد حساب خود شوید.",
          status: "error",
          id: "TOKEN_NEED", //prevent dupplicate
        });
      }

      /** The user will be transferred to the SignIn page */
      navigate(`/signin?to=${location.pathname}`, {
        replace: true,
      });
    } else if (checkProfile && !checkIPC()) {
      // Display error message to user
      showToast({
        title: "اطلاعات حساب ناقص است!",
        description:
          "لطفاً برای ارائه بهتر خدمات اطلاعات حساب خود را تکمیل کنید.",
        status: "error",
        id: "checkProfile", //prevent dupplicate
      });

      /** Checking whether user information is saved or not */
      /** The user will be transferred to the Profile page */
      navigate("/u/p", {
        state: { redirect: `${location.pathname}` },
        replace: true,
      });
    } else if (checkPassword && !checkIPS()) {
      // Display error message to user
      showToast({
        title: "گذرواژه ثبت نشده است!",
        description:
          "لطفاً برای سهولت در ورود مجدد، یک گذرواژه برای حساب خود تعیین کنید.",
        status: "error",
        id: "checkPassword", //prevent dupplicate
      });

      /** Checking whether user password is setted or not */
      /** The user will be transferred to the ChangePassword page */
      navigate("/u/p/change-password", {
        state: { redirect: `${location.pathname}` },
        replace: true,
      });
    }
  }, [isAuthenticated, location.pathname]);

  return element;
}
