const numbersFa = {
  digits: ["صفر", "یک", "دو", "سه", "چهار", "پنج", "شش", "هفت", "هشت", "نه"],
  specials: [
    "ده",
    "یازده",
    "دوازده",
    "سیزده",
    "چهارده",
    "پانزده",
    "شانزده",
    "هفده",
    "هجده",
    "نوزده",
  ],
  tens: [
    "",
    "ده",
    "بیست",
    "سی",
    "چهل",
    "پنجاه",
    "شصت",
    "هفتاد",
    "هشتاد",
    "نود",
  ],
  hundreds: [
    "",
    "صد",
    "دویست",
    "سیصد",
    "چهارصد",
    "پانصد",
    "ششصد",
    "هفتصد",
    "هشتصد",
    "نهصد",
  ],
  units: ["", "هزار", "میلیون", "میلیارد"],
};

/**
 * Converts the number, into understandable Persian text.
 */
export default function numberToPersian(value) {
  // If the number is lower than 10 returns Zero in persian.
  if (value <= 9) return numbersFa.digits[value];

  let num = value;

  // Separates the numeric string by three digits from the left.
  let numStr = num.toString();
  let parts = [];
  while (numStr.length > 0) {
    parts.push(numStr.slice(-3));
    numStr = numStr.slice(0, -3);
  }

  // Converts each section to Persian.
  let textParts = [];
  for (let i = 0; i < parts.length; i++) {
    let part = parseInt(parts[i]);
    let textPart = "";

    if (part === 0) continue;

    let hundreds = Math.floor(part / 100);
    let tens = Math.floor((part % 100) / 10);
    let digits = part % 10;

    if (hundreds > 0) {
      textPart +=
        numbersFa.hundreds[hundreds] + (tens > 0 || digits > 0 ? " و " : "");
    }

    if (tens === 1) {
      textPart += numbersFa.specials[digits];
    } else {
      if (tens > 0) {
        textPart += numbersFa.tens[tens] + (digits > 0 ? " و " : "");
      }

      if (digits > 0) {
        textPart += numbersFa.digits[digits];
      }
    }

    textPart += i > 0 ? ` ${numbersFa.units[i]}` : "";

    textParts.push(textPart);
  }

  // Connects the texts from the last to the first.
  return textParts.reverse().join(" و ");
}
