import { HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

export default function CountDownTimer({
  msDifference = 0,
  zIndex = "1",
  mainHStackProps = {},
  numberProps = {},
  labelProps = {},
  dotsProps = {},
  onEnd = () => {},
}) {
  const difference = useRef(msDifference);
  const [timerDetils, setTimerDetils] = useState({
    days: Math.floor(msDifference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((msDifference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((msDifference / 1000 / 60) % 60),
    seconds: Math.floor((msDifference / 1000) % 60),
  });

  useEffect(() => {
    if (difference.current < 1000) {
      onEnd();
      return;
    }

    const timerInterval = setInterval(() => {
      difference.current -= 1000;

      setTimerDetils({
        days: Math.floor(difference.current / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference.current / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference.current / 1000 / 60) % 60),
        seconds: Math.floor((difference.current / 1000) % 60),
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timerDetils, onEnd]);

  return (
    <HStack
      alignItems="flex-start"
      sx={{ direction: "ltr" }}
      zIndex={zIndex}
      spacing={{
        base: ".5rem",
        md: ".75rem",
      }}
      {...mainHStackProps}
    >
      {timerDetils.days > 0 ? (
        <>
          <VStack spacing=".5rem">
            <Text
              minW={{
                base: "3.9rem",
                md: "4.2rem",
              }}
              fontSize={{
                base: "xl",
                md: "2xl",
              }}
              textAlign="center"
              fontWeight="bolder"
              p="1rem"
              borderRadius="md"
              bgColor="var(--c--error)"
              color="var(--c--on-alert)"
              {...numberProps}
            >
              {timerDetils.days <= 9
                ? `0${timerDetils.days}`
                : timerDetils.days}
            </Text>

            <Text fontSize="sm" color="var(--c--subtitle)" {...labelProps}>
              روز
            </Text>
          </VStack>

          <Text fontSize="2xl" fontWeight="bolder" mt="1rem" {...dotsProps}>
            :
          </Text>
        </>
      ) : null}

      <VStack spacing=".5rem">
        <Text
          minW={{
            base: "3.9rem",
            md: "4.2rem",
          }}
          fontSize={{
            base: "xl",
            md: "2xl",
          }}
          textAlign="center"
          fontWeight="bolder"
          p="1rem"
          borderRadius="md"
          bgColor="var(--c--error)"
          color="var(--c--on-alert)"
          {...numberProps}
        >
          {timerDetils.hours <= 9 ? `0${timerDetils.hours}` : timerDetils.hours}
        </Text>

        <Text fontSize="sm" color="var(--c--subtitle)" {...labelProps}>
          ساعت
        </Text>
      </VStack>

      <Text fontSize="2xl" fontWeight="bolder" mt="1rem" {...dotsProps}>
        :
      </Text>

      <VStack spacing=".5rem">
        <Text
          minW={{
            base: "3.9rem",
            md: "4.2rem",
          }}
          fontSize={{
            base: "xl",
            md: "2xl",
          }}
          textAlign="center"
          fontWeight="bolder"
          p="1rem"
          borderRadius="md"
          bgColor="var(--c--error)"
          color="var(--c--on-alert)"
          {...numberProps}
        >
          {timerDetils.minutes <= 9
            ? `0${timerDetils.minutes}`
            : timerDetils.minutes}
        </Text>

        <Text fontSize="sm" color="var(--c--subtitle)" {...labelProps}>
          دقیقه
        </Text>
      </VStack>

      <Text fontSize="2xl" fontWeight="bolder" mt="1rem" {...dotsProps}>
        :
      </Text>

      <VStack spacing=".5rem">
        <Text
          minW={{
            base: "3.9rem",
            md: "4.2rem",
          }}
          fontSize={{
            base: "xl",
            md: "2xl",
          }}
          textAlign="center"
          fontWeight="bolder"
          p="1rem"
          borderRadius="md"
          bgColor="var(--c--error)"
          color="var(--c--on-alert)"
          {...numberProps}
        >
          {timerDetils.seconds <= 9
            ? `0${timerDetils.seconds}`
            : timerDetils.seconds}
        </Text>

        <Text fontSize="sm" color="var(--c--subtitle)" {...labelProps}>
          ثانیه
        </Text>
      </VStack>
    </HStack>
  );
}
