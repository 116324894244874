import { create } from "zustand";

//Global context to set state for user notifs log
export type UserLog = {
  date: Date;
  title?: string;
  description?: string;
  action?: string;
};

interface UserLogStore {
  logs: Array<UserLog>;
  addLog: (log: UserLog) => void;
}

export const useUserLogStore = create<UserLogStore>((set) => ({
  logs: [],
  addLog: (log) =>
    set((state) => ({
      logs: [...state.logs.filter((l) => l.title !== log.title), log],
    })),
}));

export default useUserLogStore;
