import { Text } from "@chakra-ui/react";
import { useState } from "react";

export default function ReadMoreText({
  minLength = 252,
  style = {},
  children = "",
}) {
  const [isExpanded, setExpandState] = useState(false);

  const originalText = children ? children.toString() : "";
  let text = originalText;

  if (!isExpanded) {
    text = text.substring(0, minLength);
  }

  if (text.trim().length === 0) {
    return <></>;
  }

  return (
    <Text {...style}>
      {text}{" "}
      {originalText.length > 252 ? (
        <span
          onClick={() => setExpandState((pv) => !pv)}
          style={{
            fontWeight: "bold",
            textDecoration: "underline",
            color: "var(--c--primary)",
            cursor: "pointer",
          }}
        >
          {isExpanded ? "نمایش کمتر" : "بیشتر بخوانید"}
        </span>
      ) : null}
    </Text>
  );
}
