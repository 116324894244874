import { HStack, IconButton, Input, useNumberInput } from "@chakra-ui/react";
import { forwardRef, memo } from "react";
import { MdOutlineAdd, MdOutlineRemove } from "react-icons/md";

type CustomNumberInputProps = {
  step?: number;
  defaultValue?: string | number;
  min?: number;
  max?: number;
  inputBgColor?: string;
  isDisabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (valueAsString: string, valueAsNumber: number) => void;
  placeHolder?: string;
  value?: string | number | readonly string[];
};

export default memo(
  forwardRef(function CustomInputNumber(
    props: CustomNumberInputProps,
    ref: React.Ref<HTMLInputElement>
  ) {
    const {
      value: inputValue,
      getInputProps,
      getIncrementButtonProps,
      getDecrementButtonProps,
    } = useNumberInput({
      step: props.step,
      defaultValue: props.defaultValue,
      min: props.min,
      max: props.max,
      isDisabled: props.isDisabled,
      onChange: props.onChange,
      onBlur: props.onBlur,
    });

    const incrementButtonProps = getIncrementButtonProps();
    const decrementButtonProps = getDecrementButtonProps();
    const quantityInputProps = getInputProps();

    return (
      <HStack ref={ref} w="100%" gap=".5rem">
        <IconButton
          aria-label="Minus Button"
          flex="2"
          colorScheme="yellow"
          icon={<MdOutlineRemove />}
          {...decrementButtonProps}
        />

        <Input
          flex="4"
          {...quantityInputProps}
          placeholder={props.placeHolder ?? "مبلغ مورد نظر"}
          value={props.value ? props.value : inputValue}
          textAlign="center"
          bgColor={props.inputBgColor}
        />

        <IconButton
          aria-label="Plus Button"
          flex="2"
          colorScheme="green"
          icon={<MdOutlineAdd />}
          {...incrementButtonProps}
        />
      </HStack>
    );
  })
);
