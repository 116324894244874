import React from "react";
import { Image } from "@chakra-ui/react";
import logoChannel from "../../assets/img/logo.svg";
import logoMainChannel from "../../assets/img/logoMain.svg";

export function Logo(props) {
  return <Image src={logoChannel} {...props} />;
}

export function LogoMain(props) {
  return <Image src={logoMainChannel} {...props} />;
}
