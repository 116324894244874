import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging/sw";

/** Initialize the Firebase application */
const app = initializeApp({
  apiKey: "AIzaSyA5-gikB3iSptH_8AhZeSlr93eD-MRwKIE",
  authDomain: "kapp-ee148.firebaseapp.com",
  projectId: "kapp-ee148",
  storageBucket: "kapp-ee148.appspot.com",
  messagingSenderId: "1056226934975",
  appId: "1:1056226934975:web:88a61f454611ea189c060a",
});

/** Connect and return Firebase Messaging instance if the browser supports this feature */
export const messaging = async () => (await isSupported()) && getMessaging(app);
