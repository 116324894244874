import React, { memo, useState } from "react";
import {
  Badge,
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  CardHeader,
  Avatar,
  Button,
  HStack,
  VStack,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from "@chakra-ui/react";

import { TbBell } from "react-icons/tb";
import { RiMore2Fill, RiEdit2Fill, RiDeleteBin2Fill } from "react-icons/ri";
import { passDateFormat, toJalaliDateTimeString } from "../../utils/date";
import { useMutation, useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { useAuth } from "../../hooks/useAuthContext";
import useDefaultToast from "../../hooks/useDefaultToast";
import useUserLogStore from "../../hooks/userLogs";
import { ID } from "../../types/apiTypes";
import useVIP from "../../hooks/useVIP";
import { BiDisc } from "react-icons/bi";

type Notif = {
  attributes: any; //like { "color": "red" },
  expiry: string; //like "2024-05-06",
  text: string;
  thumbnail: string; //base64 image
  title: string;
  vip: boolean;
  datetime: Date;
  read_by: number;
  who_read: Array<any>;
  _id: ID;
};

function NotificationButton() {
  const { authorizedAxios, checkForInvalidToken, isAuthenticated } = useAuth();
  const showToast = useDefaultToast();
  const { logs } = useUserLogStore(); // Access the user log store
  const [reads, setReads] = useState<Array<string>>([]);
  const [selectedNotif, setSelectedNotif] = useState<Notif | null>(null);

  const disclosure = useDisclosure();

  const getNotification = useQuery({
    queryKey: ["getNotification"],
    staleTime: Infinity,
    cacheTime: 0,
    retry: 0,
    enabled: isAuthenticated,
    queryFn: async () => {
      const res = await authorizedAxios.get(`/notification/mine/`);

      return (res?.data?.data || []) as Array<Notif>;
    },
    onError: (e: AxiosError) => {
      //TODO Handle it globaly
      if (checkForInvalidToken(e)) return;

      //maybe net error
      if (e.code === "ERR_NETWORK") {
        //ERR_NETWORK error
        return showToast({
          title: "در برقراری ارتباط با سرور خطایی روی داد!",
          description: "لطفا اتصال اینترنت خود را بررسی نمایید.",
          id: "ERR_NETWORK",
          status: "error",
        });
      }

      //unhandled error
      return showToast({
        title: "در دریافت اعلان‌ها خطایی روی داد!",
        description:
          "باعرض پوزش در دریافت اعلان‌ها خطایی روی داد از اینکه شکیبایی می کنید متشکریم.",
        id: "getNotification_UNHANDLED_ERROR",
        status: "error",
      });
    },
  });

  const markAsReadMutation = useMutation({
    mutationKey: ["markAsRead"],
    retry: 0,
    mutationFn: async (id: ID) => {
      const res = await authorizedAxios.patch(`notification/${id}/`, {
        id,
      });

      res.data.message &&
        showToast({
          title: res.data.message,
          status: res.data.success ? "success" : "error",
        });

      return null;
    },
    onSuccess: () => {},
    onError: (e: AxiosError<any>) => {
      if (checkForInvalidToken(e)) return;

      if (e.response && e.response.data?.message)
        showToast({
          title: e.response.data?.message,
          status: e.response.data?.success ? "success" : "error",
        });
    },
  });

  const notifList = getNotification.data || [];
  const unreadCount = notifList.length - reads.length;

  return (
    <Popover
      onOpen={() => {
        const unreadNotifs = notifList.filter((n) => !reads.includes(n._id));
        setReads(notifList.map((n) => n._id));
        unreadNotifs.forEach((n) => markAsReadMutation.mutate(n._id));
        disclosure.onOpen();
      }}
      onClose={disclosure.onClose}
      isOpen={disclosure.isOpen}
    >
      <PopoverTrigger>
        <Box position={"relative"}>
          <IconButton
            isLoading={getNotification.isLoading}
            variant="ghost"
            icon={<TbBell />}
            aria-label={"bell"}
          />

          {unreadCount > 0 ? (
            <Badge
              colorScheme="red"
              pos="absolute"
              variant="solid"
              bgColor="red.500"
              right={0}
              bottom={0}
              borderRadius={"50%"}
              cursor={"pointer"}
              width={"16px"}
              textAlign={"center"}
            >
              {unreadCount}
            </Badge>
          ) : null}
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton zIndex={999} />

        <Tabs>
          <TabList>
            <Tab>اعلان‌ها</Tab>
            <Tab>پیغام‌ها</Tab>
          </TabList>

          <TabPanels
            sx={{ maxHeight: "calc(100vh - 256px)", overflowY: "auto" }}
          >
            <TabPanel>
              {notifList.map((notif) => (
                <HStack
                  w="100%"
                  justifyContent="space-between"
                  spacing=".75rem"
                  key={notif._id}
                  cursor={"pointer"}
                  role="button"
                  onClick={() => {
                    disclosure.onClose();
                    setSelectedNotif(notif);
                  }}
                >
                  <Avatar
                    size="md"
                    src={notif.thumbnail}
                    name={notif.title || "پیغام"}
                  />

                  <VStack
                    flex="1"
                    alignItems="stretch"
                    spacing="0"
                    overflow={"hidden"}
                  >
                    <Text
                      textAlign={"justify"}
                      fontSize="sm"
                      fontWeight="bolder"
                      display={notif.title ? "block" : "none"}
                      noOfLines={1}
                    >
                      {notif.title}
                    </Text>

                    <Text
                      fontSize="sm"
                      color="var(--c--subtitle)"
                      textAlign={"justify"}
                      display={notif.text ? "block" : "none"}
                      noOfLines={3}
                    >
                      {notif.text}
                    </Text>

                    <Text
                      mt={1}
                      fontSize="sm"
                      color="var(--c--subtitle)"
                      fontWeight={"bold"}
                    >
                      {passDateFormat(new Date(notif.datetime))}
                    </Text>
                  </VStack>

                  {false ? (
                    <Popover placement="bottom-end">
                      <PopoverTrigger>
                        <IconButton
                          icon={<RiMore2Fill />}
                          variant="text"
                          aria-label={""}
                        />
                      </PopoverTrigger>

                      <PopoverContent w="12rem">
                        <PopoverArrow />
                        <PopoverBody>
                          <Button
                            w="100%"
                            variant="ghost"
                            colorScheme="blue"
                            justifyContent="flex-start"
                            leftIcon={<RiEdit2Fill />}
                          >
                            خوانده شده
                          </Button>

                          <Button
                            w="100%"
                            variant="ghost"
                            colorScheme="red"
                            justifyContent="flex-start"
                            leftIcon={<RiDeleteBin2Fill />}
                          >
                            حذف اعلان
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  ) : null}
                </HStack>
              ))}

              {!notifList.length && (
                <Box textAlign={"center"}>اعلانی ندارید</Box>
              )}
            </TabPanel>

            <TabPanel>
              <VStack>
                {logs.map((log) => (
                  <HStack
                    w="100%"
                    justifyContent="space-between"
                    spacing=".75rem"
                  >
                    <Avatar size="md" name={log.action || "پیغام"} />

                    <VStack
                      flex="1"
                      alignItems="stretch"
                      spacing="0"
                      overflow={"hidden"}
                    >
                      <Text
                        textAlign={"justify"}
                        fontSize="sm"
                        fontWeight="bolder"
                        display={log.title ? "block" : "none"}
                      >
                        {log.title}
                      </Text>

                      <Text
                        fontSize="sm"
                        color="var(--c--subtitle)"
                        textAlign={"justify"}
                        display={log.description ? "block" : "none"}
                      >
                        {log.description}
                      </Text>

                      <Text
                        mt={1}
                        fontSize="sm"
                        color="var(--c--subtitle)"
                        fontWeight={"bold"}
                      >
                        {passDateFormat(log.date)}
                      </Text>
                    </VStack>

                    {false ? (
                      <Popover placement="bottom-end">
                        <PopoverTrigger>
                          <IconButton
                            icon={<RiMore2Fill />}
                            variant="text"
                            aria-label={""}
                          />
                        </PopoverTrigger>

                        <PopoverContent w="12rem">
                          <PopoverArrow />
                          <PopoverBody>
                            <Button
                              w="100%"
                              variant="ghost"
                              colorScheme="blue"
                              justifyContent="flex-start"
                              leftIcon={<RiEdit2Fill />}
                            >
                              خوانده شده
                            </Button>

                            <Button
                              w="100%"
                              variant="ghost"
                              colorScheme="red"
                              justifyContent="flex-start"
                              leftIcon={<RiDeleteBin2Fill />}
                            >
                              حذف اعلان
                            </Button>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    ) : null}
                  </HStack>
                ))}

                {!logs.length && <Box textAlign={"center"}>پیغامی ندارید</Box>}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PopoverContent>

      {selectedNotif && (
        <Modal
          isOpen={!!selectedNotif}
          onClose={() => setSelectedNotif(null)}
          closeOnEsc
          scrollBehavior={"inside"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize="medium"
              display={"flex"}
              alignItems={"center"}
              gap={3}
            >
              <Avatar
                size="md"
                src={selectedNotif.thumbnail}
                name={selectedNotif.title || "پیغام"}
              />
              {selectedNotif.title}
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>{selectedNotif.text}</ModalBody>

            <ModalFooter>
              <Text
                ml={"auto"}
                fontSize="sm"
                color="var(--c--subtitle)"
                fontWeight={"bold"}
              >
                {toJalaliDateTimeString(selectedNotif.datetime)}
              </Text>

              <Button onClick={() => setSelectedNotif(null)}>بستن</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Popover>
  );
}

export default memo(NotificationButton);
