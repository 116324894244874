import { useState } from "react";

export default function useForceUpdate() {
  // integer state
  const [_, setValue] = useState(0);
  // update state to force render
  return () => setValue((value) => value + 1);
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}
