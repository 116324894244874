import {
  CreateToastFnReturn, // Define the return type of the function that creates a toast
  ToastId, // Define the type for the unique identifier of a toast
  UseToastOptions, // Define the options available for using a toast
  useToast, // Import the hook for displaying toasts
} from "@chakra-ui/react"; // Import necessary components from Chakra UI library

import useUserLogStore from "./userLogs"; // Import the custom hook for user logs
import { useMap } from "usehooks-ts";
import { useMemo } from "react";

interface DefaultUseToastOptions extends UseToastOptions {
  duration?: number; // Specify the duration for displaying the toast
  isClosable?: boolean; // Define if the toast is closable
  addToLog?: boolean; // Specify whether to add the toast to user logs
}

const defaultValues = {
  duration: 4000, // Default duration for the toast
  isClosable: true, // Default setting for closability
  addToLog: true, // Default behavior to add to user logs
} as DefaultUseToastOptions;

//Custom the toast for easier use and add other function to it like log
export function useDefaultToast(
  options: DefaultUseToastOptions = defaultValues // Define the function to create a default toast with optional options
): CreateToastFnReturn {
  const toast = useToast(options); // Initialize the toast with provided options
  const userLogStore = useUserLogStore(); // Access the user log store

  const showToast = useMemo(() => {
    // Function to show a toast with default options
    const showToast = ((options?: DefaultUseToastOptions): ToastId => {
      const defaultOptions: DefaultUseToastOptions = {
        ...options, // Spread any additional options provided
      };

      // Check if the toast with the same id is already active
      if (defaultOptions.id && toast.isActive(defaultOptions.id))
        return defaultOptions.id; // Return the existing toast id

      // Add the toast details to user logs if addToLog is true
      if (defaultOptions.addToLog) {
        userLogStore.addLog({
          date: new Date(), // Log the current date
          title: defaultOptions.title?.toString(), // Convert title to string and log
          description: defaultOptions.description?.toString(), // Convert description to string and log
        });
      }

      return toast(defaultOptions); // Display the toast with default options
    }) as CreateToastFnReturn;

    Object.assign(showToast, toast); // Assign the showToast function properties of the toast
    return showToast;
  }, [toast, userLogStore, options]);

  return showToast; // Return the function to display a default toast
}

export default useDefaultToast;
