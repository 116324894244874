import { Flex, Badge, Box, HStack, Spinner } from "@chakra-ui/react";
import { EventTimeInfo } from "../../hooks/useEventStore";

export default function CountEventHeader({
  participants,
  isLoading,
  isPublic,
  isFinish,
  text,
}: {
  participants: {
    total: number;
    count: number;
    remain: number;
    target: number;
  };
  text: {
    total: string;
    count: string;
    remain: string;
    target: string;
  };
  eventInfo: EventTimeInfo;
  isLoading: boolean;
  isFinish: boolean;
  isPublic: boolean;
}) {
  return (
    <Flex
      w="100%"
      mt=".5rem"
      mb="1rem"
      flexDirection={{ base: "column-reverse", md: "row" }}
      alignItems={{ base: "flex-start", md: "center" }}
      justifyContent={{ base: "center", md: "space-between" }}
      gap={{ base: "1.5rem", md: undefined }}
    >
      {/* <Heading as="h1" size="sm">
        وضعیت پویش
      </Heading> */}

      {participants !== null ? (
        <Flex
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
          m={"auto"}
          gap=".5rem"
        >
          <Badge
            p=".2rem .5rem"
            fontSize="sm"
            borderRadius={4}
            colorScheme="green"
            minW={"96px"}
            height={"28px"}
          >
            <HStack spacing=".5rem">
              <Box
                w=".63rem"
                h=".63rem"
                borderRadius="100%"
                bg="green"
                animation={
                  isFinish ? "" : "pulse_sm_green_anim 1s ease-out infinite"
                }
              />
              {isLoading ? (
                <Spinner m="auto" />
              ) : (
                <span>
                  {text.count || "تعداد"}:{" "}
                  {parseInt(`${participants.count}`).toLocaleString("fa")}
                </span>
              )}
            </HStack>
          </Badge>

          <Badge
            p=".2rem .5rem"
            fontSize="sm"
            borderRadius={4}
            colorScheme="red"
            minW={"96px"}
            height={"28px"}
          >
            <HStack spacing=".5rem">
              <Box
                w=".63rem"
                h=".63rem"
                borderRadius="100%"
                bg="red"
                // animation={"pulse_sm_red_anim 1s ease-out infinite"}
              />

              {isLoading ? (
                <Spinner m="auto" />
              ) : (
                <span>
                  {participants.remain >= 0
                    ? (text.remain || "مانده") +
                      `: ${participants.remain.toLocaleString("fa")} `
                    : `جبران: ${(participants.remain * -1).toLocaleString(
                        "fa"
                      )} `}
                </span>
              )}
            </HStack>
          </Badge>

          <Badge
            p=".2rem .5rem"
            fontSize="sm"
            borderRadius={4}
            colorScheme="gray"
          >
            <HStack spacing=".5rem">
              {isLoading ? (
                <Spinner m="auto" />
              ) : (
                <>
                  {!isPublic && (
                    <span>
                      {text.target || "هدف شما"}{" "}
                      {`: ${participants.target.toLocaleString("fa")} `}
                    </span>
                  )}

                  {isPublic && (
                    <span>
                      {text.target || "هدف پویش"}{" "}
                      {`: ${participants.target.toLocaleString("fa")} `}
                    </span>
                  )}
                </>
              )}
            </HStack>
          </Badge>

          {/* <Badge
            p=".2rem .5rem"
            fontSize="sm"
            borderRadius={4}
            colorScheme="telegram"
          >
            <HStack spacing=".5rem">
              <span>{`کل : ${participants.total.toLocaleString("fa")} `}</span>
            </HStack>
          </Badge> */}
        </Flex>
      ) : null}
    </Flex>
  );
}
