import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebase-messaging-sw";

/** Firebase VAPID_KEY that use for creating device token */
const VAPID_KEY =
  "BA32n6l4LS0O-uaHZL8EVZyhgwlmxYDhBnZj42fpToTGPivlJtdnXzjk_noyMBsB2iJPq95ltajo8mFHzL3wL5I";

/** Getting permission to receive notifications from the user */
export async function requestNotificationPermission() {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    /** Get the device token if the user agrees */
    return await getUserDeviceToken();
  }
}

/** Get the device token */
export async function getUserDeviceToken() {
  const msg = await messaging();

  if (msg) {
    try {
      /** If the user does not agree to receive the notification or the browser does not support receiving the notification, the null value is returned */
      const currentToken = await getToken(msg, { vapidKey: VAPID_KEY });

      if (currentToken) {
        /** Listen to incomming notifications and show them to the user */
        onMessage(msg, (payload) => {
          new Notification(payload.notification.title, {
            body: payload.notification.body,
            image: payload.notification.image,
            icon: payload.notification.icon,
          });
        });

        return currentToken;
      } else {
        console.log("No registration token available!");
        return await getUserDeviceToken();
      }
    } catch (error) {
      console.log("An error occurred while retrieving token:", error);
      return await getUserDeviceToken();
    }
  } else {
    console.log("Your browser does not support Push-Notification!");
  }

  return null;
}
