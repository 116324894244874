import { FormControl, FormLabel } from "@chakra-ui/react";
import { ReactNode, forwardRef, memo } from "react";
import CustomNumberInput from ".";

//TODO CHECK FORWARD REF
export default memo(
  forwardRef(function NumberInputPicker(
    {
      title = "",
      onBlur = () => {},
      onChange = () => {},
      isInvalid = false,
      errors = <></>,
      ...props
    }: {
      title: string;
      onBlur?: () => void;
      onChange: (v: string) => void;
      isInvalid?: boolean;
      errors?: ReactNode;
      props?: any;
    },
    ref: React.Ref<HTMLInputElement>
  ) {
    return (
      <>
        <FormControl isInvalid={isInvalid}>
          <FormLabel mt={4}>{title}</FormLabel>

          <CustomNumberInput
            {...props}
            //@ts-ignore
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
          />

          {errors}
        </FormControl>
      </>
    );
  })
);
