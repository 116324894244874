import { LogoMain } from "../Logo";
import {
  Flex,
  CircularProgress,
  ScaleFade,
  Progress,
  Heading,
} from "@chakra-ui/react";

export default function LoadingFallback() {
  return (
    <ScaleFade initialScale={0.1} in>
      <Flex
        w="100vw"
        h="100vh"
        justifyContent="center"
        justifyItems="center"
        alignItems="center"
        flexDirection="column"
        alignContent="center"
      >
        <LogoMain maxWidth="333px" />

        <Heading mt="2rem" mb="1rem" fontSize="1.1rem" fontWeight="bolder">
          خادمان امام زمان عجل الله تعالی فرجه
        </Heading>

        {/* Linear or Circular */}
        <Progress
          height=".5rem"
          borderRadius="2xl"
          mt={5}
          width="15rem"
          isIndeterminate
        />

        {/* <CircularProgress mt={5} isIndeterminate /> */}
      </Flex>
    </ScaleFade>
  );
}
