import React, { useEffect, useRef, useState } from "react";
import numberToPersian from "../utils/numberToPersian";
import { useMutation, useQuery } from "react-query";
import {
  BASE_URL,
  USER_AVATAR_KEY,
  USER_PROFILE_INFO_KEY,
  useAuth,
} from "../hooks/useAuthContext";
import axios, { AxiosError } from "axios";
import PageHeader from "../components/PageHeader";
import {
  ScaleFade,
  Flex,
  Text,
  Card,
  CardBody,
  FormLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Button,
  useToast,
  Skeleton,
  Tag,
  Box,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Select,
  Stack,
  useBreakpointValue,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import CustomNumberInput from "../components/CustomNumberInput";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useDefaultToast from "../hooks/useDefaultToast";
import { ApiResponse, Bill } from "../types/apiTypes";
import ExpandableText from "../components/ExpandableText";

import EitaaIcon from "../components/Icons/Eitaa";
import RubikaIcon from "../components/Icons/Rubika";
import BaleIcon from "../components/Icons/Bale";
import VirastiIcon from "../components/Icons/Virasti";
import { FaPlus } from "react-icons/fa6";
import { register } from "../serviceWorker";
import { SubstituteStatus } from "./ShamimFamily/SubstituteDrawer";
import LordIcon from "../components/LordIcon";

export default function Landing() {
  const videoDrawerDisclosure = useDisclosure();
  const drawerPlacement = useBreakpointValue(
    {
      base: "bottom",
      lg: "right",
    },
    { fallback: "base" }
  );
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // videoRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const onLoad = () => {
    // videoRef.current?.play();
    videoRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  function playFullscreen() {
    const elem = videoRef.current;
    if (!elem) return;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      //@ts-ignore
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      //@ts-ignore
      elem.webkitRequestFullscreen();
      //@ts-ignore
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      //@ts-ignore
      elem.msRequestFullscreen();
    }

    elem.play();
  }

  return (
    <ScaleFade initialScale={0.1} in>
      <Flex w="100%" flexDirection="column">
        <PageHeader
          //@ts-ignore
          title={<>به کانون خادمان و عاشقان حضرت صاحب الزمان خوش آمدید</>}
          drawerTitle=""
        >
          <Flex flexDirection="column" alignItems="stretch" gap="1rem">
            <Text textAlign="justify">
              برای حمایت از کانون خادمان امام زمان (عج) و عضویت در خانواده شمیم
              مهدوی
            </Text>
          </Flex>
        </PageHeader>

        <Flex
          flexDirection={{ base: "column" }}
          alignItems="flex-start"
          gap="2rem"
        >
          <Flex w="100%" flex="1" flexDirection="column" gap="1.5rem">
            <Card w="50%" overflow="hidden" m={"auto"}>
              <video
                width="100%"
                height="100%"
                controls
                // autoPlay={true}
                ref={videoRef}
                onLoad={onLoad}
                // muted
              >
                <source src="/intro.mp4" type="video/mp4" />
                مرورگر شما، پخش ویدیو پشتیبانی نمیکند.
              </video>

              {!isPlaying && (
                <>
                  <Image
                    position={"absolute"}
                    w={"100%"}
                    h={"100%"}
                    src={"/intro.jpg"}
                    onClick={() => {
                      setIsPlaying(true);
                      playFullscreen();
                    }}
                  />

                  <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    borderRadius={"md"}
                    bg={"#00000088"}
                    textAlign={"center"}
                    p={2}
                    onClick={() => {
                      setIsPlaying(true);
                      playFullscreen();
                    }}
                  >
                    <LordIcon
                      style={{
                        height: "64px",
                        width: "64px",
                        margin: "auto",
                        color: "#fff",
                      }}
                      src="https://cdn.lordicon.com/becebamh.json"
                      trigger="loop"
                      delay="666"
                    />
                    <Text textAlign={"center"}>برای پخش فیلم کلیک کنید</Text>
                  </Box>
                </>
              )}
            </Card>
          </Flex>

          {/* <Flex flex="1" flexDirection="column" gap="1.5rem">
            <Card overflow="hidden">
              <Image height={"50%"} w={"50%"} src={"/intro.jpg"} />
            </Card>
          </Flex> */}

          <Box
            whiteSpace="pre-wrap"
            textAlign="center"
            color="gray.500"
            width="100%"
          >
            لطفا از طریق دکمه‌های زیر در کانون عضو شوید
            <SimpleGrid
              w="100%"
              px={{ md: "9%" }}
              py="1.5rem"
              gridTemplateColumns="repeat(2, 1fr)"
              alignItems="center"
              gap=".75rem"
            >
              <Button
                as="a"
                href="https://eitaa.com/khademan_emamezaman/12839"
                target="_blank"
                rightIcon={<EitaaIcon />}
              >
                گروه ایتا
              </Button>
              <Button
                as="a"
                href="https://rubika.ir/khademan_emamezaman"
                target="_blank"
                rightIcon={<RubikaIcon />}
              >
                گروه روبیکا
              </Button>

              <Button
                as="a"
                href="https://ble.ir/khademan_emamezaman"
                target="_blank"
                rightIcon={<BaleIcon />}
              >
                گروه بَله
              </Button>
              <Button
                as="a"
                href="https://virasty.com/Khdeman_emamezaman"
                target="_blank"
                rightIcon={<VirastiIcon />}
              >
                گروه ویراستی
              </Button>
            </SimpleGrid>
            {/* <Box
              mt={4}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
            >
              <Box p={2} flex={1} >
                <Button
                  as="a"
                  href="https://eitaa.com/khademan_emamezaman/12839"
                  target="_blank"
                  rightIcon={<EitaaIcon />}
                >
                  گروه ایتا
                </Button>
                <Button
                  as="a"
                  href="https://rubika.ir/khademan_emamezaman"
                  target="_blank"
                  rightIcon={<RubikaIcon />}
                >
                  گروه روبیکا
                </Button>
              </Box>
              <Box p={2} flex={1}>
                <Button
                  as="a"
                  href="https://ble.ir/khademan_emamezaman"
                  target="_blank"
                  rightIcon={<BaleIcon />}
                >
                  گروه بَله
                </Button>
                <Button
                  as="a"
                  href="https://virasty.com/Khdeman_emamezaman"
                  target="_blank"
                  rightIcon={<VirastiIcon />}
                >
                  گروه ویراستی
                </Button>
              </Box>
            </Box> */}
          </Box>
        </Flex>
      </Flex>

      <Drawer
        isOpen={videoDrawerDisclosure.isOpen}
        placement={drawerPlacement as any}
        size="md"
        onClose={videoDrawerDisclosure.onClose}
        onCloseComplete={() => {}}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>ایجاد نیابتی جدید</DrawerHeader>

          <DrawerBody>
            <Flex w="100%" flex="1" flexDirection="column" gap="1.5rem">
              <Card w="100%" overflow="hidden">
                <video width="100%" height="100%" controls autoPlay={true}>
                  <source src="/intro.mp4" type="video/mp4" />
                  مرورگر شما، پخش ویدیو پشتیبانی نمیکند.
                </video>
              </Card>
            </Flex>
          </DrawerBody>

          <DrawerFooter as={HStack} alignItems="center" gap=".75rem">
            <Button
              flex="1"
              variant="outline"
              onClick={videoDrawerDisclosure.onClose}
            >
              منصرف شدم
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </ScaleFade>
  );
}
