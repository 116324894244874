import { create } from "zustand";

//todo add permission or others...
interface EventStore {
  play: () => void;
  selectedEventGDate: string;
  setPLay: (callback: () => void) => void;
  setSelectedEventGDate: (selectedEventGDate: string) => void;
}

let timeoutId;
const useEventStore = create<EventStore>((set) => ({
  play: () => {},
  selectedEventGDate: "",
  setPLay: (callback) => set(() => ({ play: callback })),
  setSelectedEventGDate: (selectedEventGDate) => {
    set(() => ({ selectedEventGDate }));
  },
  setSelectedEventGDateTimer: (selectedEventGDate) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => set(() => ({ selectedEventGDate })), 333);
  },
}));

export const eventTypes = {
  day_based_event: "پویش روزانه",
  count_based_event: "پویش شمارشی",
};

export interface EventTimeInfo {
  eventData: any;
  eventId: any;
  isClosed: any;
  isPrivate: boolean;
  isOver: any;
  todayGDate: string;
  todayIndex: number;
  attendedDaysNumber: number;
  absentDaysNumber: number;
  remainDaysNumber: number;
  datesList: any[];
  isAttendToday: boolean;
  isOnline: boolean;
  untilStart: number;
  passDateFormat: (date: any) => any;
}

export default useEventStore;
